import React, { Suspense } from 'react'
import './App.css'
import './index.css'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import Loader from './components/shared/Loader'

const Analytics = React.lazy(() => import('./components/dashboard/analytics/Analytics'))
const APIs = React.lazy(() => import('./components/dashboard/apis/APIs'))
const Billing = React.lazy(() => import('./components/dashboard/billing/Billing'))
const ChangePassword = React.lazy(() => import('./components/dashboard/changePassword/ChangePassword'))
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'))
const EndUser = React.lazy(() => import('./components/dashboard/agreements/EndUser'))
const ForgotPassword = React.lazy(() => import('./pages/login/ForgotPassword'))
const Gallery = React.lazy(() => import('./components/dashboard/gallery/Gallery'))
const Games = React.lazy(() => import('./components/dashboard/games/Games'))
const HomePage = React.lazy(() => import('./pages/home/HomePage'))
const LoginPage = React.lazy(() => import('./pages/login/LoginPage'))
const PrivacyPolicy = React.lazy(() => import('./components/dashboard/agreements/PrivacyPolicy'))
const SignUpPage = React.lazy(() => import('./pages/signup/SignUpPage'))
const UpdateProfile = React.lazy(() => import('./components/dashboard/updateProfile/UpdateProfile'))

function App () {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='forgot' element={<ForgotPassword />} />
          <Route path='signup' element={<SignUpPage />} />
          <Route path='gallery' element={<Gallery />} />
          <Route path='agreements/end-user' element={<EndUser />} />
          <Route path='agreements/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/dashboard/*' element={<Dashboard />}>
            <Route path='analytics' element={<Analytics />} />
            <Route path='apis' element={<APIs />} />
            <Route path='billing' element={<Billing />} />
            <Route path='changePassword' element={<ChangePassword />} />
            <Route path='gallery' element={<Gallery />} />
            <Route path='games' element={<Games />} />
            <Route path='updateProfile' element={<UpdateProfile />} />
            <Route path='agreements/end-user' element={<EndUser />} />
            <Route path='agreements/privacy-policy' element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  )
}

export default App
